import Link from 'next/link'
import { Box, Heading, VStack, SimpleGrid } from "@chakra-ui/react"
import { Center } from "@chakra-ui/react"
import fetcher from '../utils/fetcher'
import { useSession, signOut } from 'next-auth/client'
import { useEffect } from 'react'


export default function HomePage({data}) {
    // keys 는 department 의 데이터중에서도 key 값만 배열해주는 함수
    // const data = [{"id":1,"Name":"정형외과","Code":"OS","published_at":"2021-04-04T01:17:43.000Z","created_at":"2021-04-04T01:17:26.000Z","updated_at":"2021-04-04T01:17:43.000Z","Thumbnail":null,"parts":[{"id":1,"department":1,"Name":"척추","published_at":"2021-04-11T01:53:13.000Z","created_at":"2021-04-04T01:25:27.000Z","updated_at":"2021-04-11T01:53:13.000Z"},{"id":2,"department":1,"Name":"무릎","published_at":"2021-04-11T01:53:19.000Z","created_at":"2021-04-11T00:52:30.000Z","updated_at":"2021-04-11T01:53:20.000Z"}]}]
    // session 로그인을 하면 정보를 담고 있다, loading 은 정보를 가지고 오는 동안, 로그인을 안했을때 두가지 상태를 의미
    // 로그인 처리를 사용하지 않음
    const [session, loading] = useSession()

    useEffect(() => {
        if (session) {
            signOut()
        }
    }, [])
    return (
        <>
            <VStack>
                <Center>
                    <SimpleGrid mt={5} columns={[1,2,3,4]} gap={30} >
                        {
                            data?.map(function (item) {
                                return <Link key={item.id} href={`/departments/${item.id}`}>
                                    <a>
                                       <Box borderColor="gray.600" borderWidth="5px" borderRadius="md" 
                                        overflow="hidden">
                                            <Center padding="40px" w="auto" h="auto" bg="blue.200">
                                                <Heading color="gray.600" fontSize="20px">
                                                    {item.Name}
                                                </Heading>
                                            </Center> 
                                        </Box>
                                    </a>
                                </Link>
                            })
                        }
                    </SimpleGrid>

                </Center>
            </VStack>
        </>
    )
}


export async function getStaticProps(context) {
    const response = await fetcher(`departments`);
    return {
      props: {
        data: response,
      }, // will be passed to the page component as props
    }
  }